@charset "UTF-8";
/* ------------------------------------------------------
デスクトップ・スマホ共通
 ------------------------------------------------------ */

 $color-orange: #D07171;
 $color-brown: rgb(130, 20, 40);
 $color-red: #D21E22;
 $color-base: #444;
 $color-light-brown: #8b2430;


body {
  margin: 0;
  padding: 0;
  color: $color-base;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 300;
  font-feature-settings : "palt";
  background: #eee;
  @media (max-width: 919px){
    &.sp-menu-open{
      overflow: hidden;
    }
  }
}
h1,h2 {
  font-weight: 300;
  letter-spacing: 0.1rem;
}
table{margin: 1em 0;width: 100%;font-size:110%;}
th,td{vertical-align: top;padding:0.4em;border: 1px dotted #5D3F37;}
th{text-align: left;}
td ol, td ul{margin: 0;}

dt{font-weight: bold;}
dd{margin: 0 0 1em;}

a{
  color: $color-base;
  &:hover{
    color: $color-base;
  }
}
.btn-primary{
  background:$color-base;
  border-color: $color-base;
  padding:.6rem 1.2rem;
  font-size: .9rem;
  border-radius: 8px;
  font-weight: normal;
  transition: 0.5s;
  &:hover{
    background: $color-brown;
    border-color: $color-brown;
  }
}

/* ---------------------------
header
*/
header{
  position: fixed;
  width: 100%;
  top:0;
  z-index: 1200;
  background: rgba(255,255,255,.95);
  .container{
    max-width: 1140px;
  }
  .header-wrap{
    position: relative;
    display: flex;
    justify-content:space-between;
    align-items: flex-end;
    margin-bottom: 6px;
  }
  .title{
    margin: 1.1rem 0 14px;
    a{
      color: $color-base;
      font-size: 1.6rem;
      font-weight: normal;
      &:hover{
        text-decoration: none;
      }
      .logo{
        img{
          width: 300px;
          @media (max-width: 480px){
            width: 200px;
          }
        }
      }
    }
  }
  .menu-trigger {
    display: inline-block;
    width: 28px;
    height: 34px;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 18px;
    z-index: 2002;
    transform: translateX(0);
    span {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-base;
      transition: all .5s;
      &:nth-of-type(1) {
        top: 3px;
      }
      &:nth-of-type(2) {
        top: 9px;
      }
      &:nth-of-type(3) {
        top: 15px;
      }
    }
    div{
      font-size: 10px;
      text-align: center;
      bottom: 0;
      position: absolute;
      letter-spacing: -.04rem;
    }
    &.active {
      transform: translateX(0);
      z-index: 3000;
      span {
        background-color: $color-base !important;
        &:nth-of-type(1) {
          transform: translateY(6px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
  }
  nav{
    display: none;
    width: 100vw;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1600;
    transform: translate(-100vw);
    transition: all .5s;
    overflow-y:scroll !important;
    overflow-x:hidden !important;
    padding: 80px 8px 0;
    ul {
      margin: 0 auto 5px;
      padding-left: 0;
      display: flex;
      justify-content: center;
      max-width: 900px;
      li {
        padding: .5rem;
        width: 30%;
        a {
          color: $color-base;
          display: inline-block;
          width: 100%;
          text-decoration: none;
          text-align: center;
          img{
            border: 1px solid #eee;
            background: #fff;
            margin-bottom: .4rem;
          }
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    &.open {
      display: block;
      transform: translateX(0);
      overflow:hidden;
      ul{
        box-sizing:border-box;
        li {
          list-style: none;
        }
      }
    }
  }
  .overlay {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background: rgba(204,204,204,.95);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .5s;
    top: 0;
    z-index: 0;
    &.open {
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 1001;
    }
  }
}

/* ---------------------------
footer
*/
footer {
  text-align: center;
  color: #666;
  #footer-wrap{
    padding: 20px 0 40px;
    position: relative;
    .copyright {
      opacity: 0.6;
      font-size: .9rem;
      margin-top: 1rem;
    }
    @media (max-width: 767px){
      padding-bottom: 25px;
    }
  }

}
#page-top {
  position: fixed;
  display: none !important;
  @media (min-width: 768px){
    bottom: 20px;
    z-index: 3000;
  }
  @media (max-width: 767px){
    bottom: 10px;
  }
  right: 10px;
  a {
    display: block;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    color: $color-base;
    text-decoration: none;
    font-size: 10px;
    border-radius:50%;
    height: 50px;
    width: 50px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    span{
      display: block;
    }
  }
}


/* ---------------------------
contents
*/
#contents{
  padding: 77px 0 30px;
  h1{
    font-size: 1.8rem;
    margin-bottom: 20px;
    span{
      font-size: 70%;
    }
  }
  p{
    font-size: .9rem;
    line-height: 1.6;
    color: #444;
    letter-spacing: 0.1rem;
  }
  h2 {
    font-size: 1.3rem;
    margin-top: 0.4rem;
    font-weight: bold;
  }
  h3{
    font-size: 1.2rem;
  }
  img{
    display: inline-block;
  }
  @media (max-width: 767px){
    h2{
      font-size: 1.2rem;
    }
  }
}


/* ---------------------------
ホーム
*/
.home{
  header .title{
    display: block;
  }
  #contents{
    z-index: 1000 !important;
    position: relative;
    #feature{
      background: #FBF6EB;
      .slider-home{
        margin-bottom: 30px;
      }
      .slide-order{
        strong{
          font-weight: 300;
        }
        i{
          padding-right: .3rem;
        }
        @media (min-width: 920px){
          z-index: 1000;
          background: #FBF6EB;
          font-size: 2.4rem;
          padding:5px 20px;
          position: absolute;
          top: 120px;
          right:30px;
          box-shadow: 0 3px 3px rgba(0,0,0,.1);
          strong{
            font-size: 2.8rem;
          }
          i{
            font-size: 2.2rem;
          }
        }
        @media (max-width: 919px){
          font-size: 1.6rem;
          text-align: center;
          i{
            font-size: 1.4rem;
            margin-left: .5rem;
          }
          @media (min-width: 400px){
            padding:10px;
            br{
              display: none;
            }
          }
          @media (max-width: 399px){
            padding:0 0 5px;
            line-height: 1.3;
            strong{
              font-size: 1rem;
              font-weight: bold;
            }
          }
        }
      }
    }
    #outline{
      h2{
        font-size: 2rem;
        font-weight: bold;
      }
      h3{
        line-height: 1.5;
      }
    }
    #shop{
      a{
        text-decoration: none;
        h3{
          font-size: 1.2rem;
          margin-bottom: .2rem;
          margin-top: .5rem;
        }
        p{
          margin-bottom: 0;
          padding: 0 .7rem;
          line-height: 1.4;
        }
        img{
          background: #fff;
          border:1px solid #ccc;
          margin:0 15px;
        }
        &:hover{
          opacity: .9;
          h3,p{
            color: #999;
          }
        }
      }
    }
    #company{
      h3{
        margin-top: 1rem;
        img{
          width:220px;
        }
      }
      table{
        tr{
          th,td{
            border: 0;
            font-size: .9rem;
            padding: .2rem;
            vertical-align: text-top;
          }
          th{
            font-weight: normal;
          }
        }
      }
    }
  }
}
.shop{
  #contents{
    h1#logo{
      font-size: .9rem;
      padding-bottom: 2rem;
      img{
        width:200px;
        margin:0 auto;
        display: block;
      }
    }
    #outline{
      p{
        a{
          text-decoration: underline;
        }
      }
    }
    #sns{
      a{
        margin: .3rem;
      }
    }
    #instagram{
      h2{
        font-size: 2rem;
      }
      .insta-photos a:before, .insta-photos div:before{
        content: "";
        display: block;
        padding-top: 100%;
      }
      .insta-photos a img, .insta-photos a video, .insta-photos div img, .insta-photos div video{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: bottom;';
      }
      .insta-photos > div{
        transition: all .2rem linear;
        &:hover{
          transform: scale(1.02);
        }
      }
    }
    #gmap{
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
