@charset "UTF-8";
/* ------------------------------------------------------
デスクトップ・スマホ共通
 ------------------------------------------------------ */
body {
  margin: 0;
  padding: 0;
  color: #444;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 300;
  font-feature-settings: "palt";
  background: #eee; }
  @media (max-width: 919px) {
    body.sp-menu-open {
      overflow: hidden; } }

h1, h2 {
  font-weight: 300;
  letter-spacing: 0.1rem; }

table {
  margin: 1em 0;
  width: 100%;
  font-size: 110%; }

th, td {
  vertical-align: top;
  padding: 0.4em;
  border: 1px dotted #5D3F37; }

th {
  text-align: left; }

td ol, td ul {
  margin: 0; }

dt {
  font-weight: bold; }

dd {
  margin: 0 0 1em; }

a {
  color: #444; }
  a:hover {
    color: #444; }

.btn-primary {
  background: #444;
  border-color: #444;
  padding: .6rem 1.2rem;
  font-size: .9rem;
  border-radius: 8px;
  font-weight: normal;
  transition: 0.5s; }
  .btn-primary:hover {
    background: #821428;
    border-color: #821428; }

/* ---------------------------
header
*/
header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1200;
  background: rgba(255, 255, 255, 0.95); }
  header .container {
    max-width: 1140px; }
  header .header-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 6px; }
  header .title {
    margin: 1.1rem 0 14px; }
    header .title a {
      color: #444;
      font-size: 1.6rem;
      font-weight: normal; }
      header .title a:hover {
        text-decoration: none; }
      header .title a .logo img {
        width: 300px; }
        @media (max-width: 480px) {
          header .title a .logo img {
            width: 200px; } }
  header .menu-trigger {
    display: inline-block;
    width: 28px;
    height: 34px;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 18px;
    z-index: 2002;
    transform: translateX(0); }
    header .menu-trigger span {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #444;
      transition: all .5s; }
      header .menu-trigger span:nth-of-type(1) {
        top: 3px; }
      header .menu-trigger span:nth-of-type(2) {
        top: 9px; }
      header .menu-trigger span:nth-of-type(3) {
        top: 15px; }
    header .menu-trigger div {
      font-size: 10px;
      text-align: center;
      bottom: 0;
      position: absolute;
      letter-spacing: -.04rem; }
    header .menu-trigger.active {
      transform: translateX(0);
      z-index: 3000; }
      header .menu-trigger.active span {
        background-color: #444 !important; }
        header .menu-trigger.active span:nth-of-type(1) {
          transform: translateY(6px) rotate(-45deg); }
        header .menu-trigger.active span:nth-of-type(2) {
          opacity: 0; }
        header .menu-trigger.active span:nth-of-type(3) {
          transform: translateY(-6px) rotate(45deg); }
  header nav {
    display: none;
    width: 100vw;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1600;
    transform: translate(-100vw);
    transition: all .5s;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    padding: 80px 8px 0; }
    header nav ul {
      margin: 0 auto 5px;
      padding-left: 0;
      display: flex;
      justify-content: center;
      max-width: 900px; }
      header nav ul li {
        padding: .5rem;
        width: 30%; }
        header nav ul li a {
          color: #444;
          display: inline-block;
          width: 100%;
          text-decoration: none;
          text-align: center; }
          header nav ul li a img {
            border: 1px solid #eee;
            background: #fff;
            margin-bottom: .4rem; }
          header nav ul li a:hover {
            text-decoration: none; }
    header nav.open {
      display: block;
      transform: translateX(0);
      overflow: hidden; }
      header nav.open ul {
        box-sizing: border-box; }
        header nav.open ul li {
          list-style: none; }
  header .overlay {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background: rgba(204, 204, 204, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .5s;
    top: 0;
    z-index: 0; }
    header .overlay.open {
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 1001; }

/* ---------------------------
footer
*/
footer {
  text-align: center;
  color: #666; }
  footer #footer-wrap {
    padding: 20px 0 40px;
    position: relative; }
    footer #footer-wrap .copyright {
      opacity: 0.6;
      font-size: .9rem;
      margin-top: 1rem; }
    @media (max-width: 767px) {
      footer #footer-wrap {
        padding-bottom: 25px; } }

#page-top {
  position: fixed;
  display: none !important;
  right: 10px; }
  @media (min-width: 768px) {
    #page-top {
      bottom: 20px;
      z-index: 3000; } }
  @media (max-width: 767px) {
    #page-top {
      bottom: 10px; } }
  #page-top a {
    display: block;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    color: #444;
    text-decoration: none;
    font-size: 10px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); }
    #page-top a span {
      display: block; }

/* ---------------------------
contents
*/
#contents {
  padding: 77px 0 30px; }
  #contents h1 {
    font-size: 1.8rem;
    margin-bottom: 20px; }
    #contents h1 span {
      font-size: 70%; }
  #contents p {
    font-size: .9rem;
    line-height: 1.6;
    color: #444;
    letter-spacing: 0.1rem; }
  #contents h2 {
    font-size: 1.3rem;
    margin-top: 0.4rem;
    font-weight: bold; }
  #contents h3 {
    font-size: 1.2rem; }
  #contents img {
    display: inline-block; }
  @media (max-width: 767px) {
    #contents h2 {
      font-size: 1.2rem; } }

/* ---------------------------
ホーム
*/
.home header .title {
  display: block; }

.home #contents {
  z-index: 1000 !important;
  position: relative; }
  .home #contents #feature {
    background: #FBF6EB; }
    .home #contents #feature .slider-home {
      margin-bottom: 30px; }
    .home #contents #feature .slide-order strong {
      font-weight: 300; }
    .home #contents #feature .slide-order i {
      padding-right: .3rem; }
    @media (min-width: 920px) {
      .home #contents #feature .slide-order {
        z-index: 1000;
        background: #FBF6EB;
        font-size: 2.4rem;
        padding: 5px 20px;
        position: absolute;
        top: 120px;
        right: 30px;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1); }
        .home #contents #feature .slide-order strong {
          font-size: 2.8rem; }
        .home #contents #feature .slide-order i {
          font-size: 2.2rem; } }
    @media (max-width: 919px) {
      .home #contents #feature .slide-order {
        font-size: 1.6rem;
        text-align: center; }
        .home #contents #feature .slide-order i {
          font-size: 1.4rem;
          margin-left: .5rem; } }
  @media (max-width: 919px) and (min-width: 400px) {
    .home #contents #feature .slide-order {
      padding: 10px; }
      .home #contents #feature .slide-order br {
        display: none; } }
  @media (max-width: 919px) and (max-width: 399px) {
    .home #contents #feature .slide-order {
      padding: 0 0 5px;
      line-height: 1.3; }
      .home #contents #feature .slide-order strong {
        font-size: 1rem;
        font-weight: bold; } }
  .home #contents #outline h2 {
    font-size: 2rem;
    font-weight: bold; }
  .home #contents #outline h3 {
    line-height: 1.5; }
  .home #contents #shop a {
    text-decoration: none; }
    .home #contents #shop a h3 {
      font-size: 1.2rem;
      margin-bottom: .2rem;
      margin-top: .5rem; }
    .home #contents #shop a p {
      margin-bottom: 0;
      padding: 0 .7rem;
      line-height: 1.4; }
    .home #contents #shop a img {
      background: #fff;
      border: 1px solid #ccc;
      margin: 0 15px; }
    .home #contents #shop a:hover {
      opacity: .9; }
      .home #contents #shop a:hover h3, .home #contents #shop a:hover p {
        color: #999; }
  .home #contents #company h3 {
    margin-top: 1rem; }
    .home #contents #company h3 img {
      width: 220px; }
  .home #contents #company table tr th, .home #contents #company table tr td {
    border: 0;
    font-size: .9rem;
    padding: .2rem;
    vertical-align: text-top; }
  .home #contents #company table tr th {
    font-weight: normal; }

.shop #contents h1#logo {
  font-size: .9rem;
  padding-bottom: 2rem; }
  .shop #contents h1#logo img {
    width: 200px;
    margin: 0 auto;
    display: block; }

.shop #contents #outline p a {
  text-decoration: underline; }

.shop #contents #sns a {
  margin: .3rem; }

.shop #contents #instagram h2 {
  font-size: 2rem; }

.shop #contents #instagram .insta-photos a:before, .shop #contents #instagram .insta-photos div:before {
  content: "";
  display: block;
  padding-top: 100%; }

.shop #contents #instagram .insta-photos a img, .shop #contents #instagram .insta-photos a video, .shop #contents #instagram .insta-photos div img, .shop #contents #instagram .insta-photos div video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: bottom;'; }

.shop #contents #instagram .insta-photos > div {
  transition: all .2rem linear; }
  .shop #contents #instagram .insta-photos > div:hover {
    transform: scale(1.02); }

.shop #contents #gmap {
  position: relative;
  width: 100%;
  padding-top: 56.25%; }
  .shop #contents #gmap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
